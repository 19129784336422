<template>
  <div class="login-box">
    <div class="login-contain">
      <div class="login-header">
        <p>欢迎登录</p>
      </div>
      <div class="form-group">
        <div class="form-item">
          <label>
            <img :src="require('@/assets/images/mobile/user.png')" alt="">
          </label>
          <input v-model="formInline.username" type="text" placeholder="请输入账号">
        </div>
        <div class="form-item">
          <label>
            <img :src="require('@/assets/images/mobile/password.png')" alt="">
          </label>
          <input v-model="formInline.password" type="password" placeholder="请输入密码">
        </div>
      </div>
      <div class="button-group">
        <button class="login-btn" @click="onLogin">登录</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'MobileLogin',
  data () {
    return {
      formInline: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    ...mapActions('user', ['login']),
    async onLogin () {
      const { username, password } = this.formInline
      if (!username) {
        this.$message({
          message: '请输入用户名',
          type: 'error',
          duration: 2 * 1000
        })
        return false
      } else if (!password) {
        this.$message({
          message: '请输入密码',
          type: 'error',
          duration: 2 * 1000
        })
        return false
      }
      await this.login(this.formInline)
      await this.$router.push('/sidebar').catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
@import "index";
</style>
